.define-table{
    border-collapse:collapse;
    border-spacing:0;
    border-left:1px solid #888;
    border-top:1px solid #888;
}
.define-table th,.define-table td{
    border-right:1px solid #888;
    border-bottom:1px solid #888;
    padding:5px 15px;
}
.define-table th{
    font-weight:bold;background:#ccc;
}
.payment table,.wallet table{
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}
.payment table td,.wallet table td{
    border-left: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    padding: 10px;
}
.payment table tr:first-child,.wallet table tr:first-child{
    background: #fafafa;
}
.text-bold{
    font-weight: bold;
}
.text-500{
    font-weight: 500;
}
.payment,.wallet{
    position: relative;
}
.loading{
    position: absolute;
    left: 50%;
    margin-left:-7px;
    top: 200px;
    z-index: 999;
}
.text-center{
    text-align: center;
}
.color-light{
    color: rgba(0,0,0,.50) !important;
}
.color-red{
    color:red !important;
}
.padding-0-3{
    padding: 0 3px !important;
}
.padding-0-5{
    padding: 0 5px !important;
}
.padding-10{
    padding: 10px !important;
}
.padding-20{
    padding: 20px !important;
}
.padding-20-0{
    padding: 20px 0 !important;
}
.margin-top-10{
    margin-top: 10px;
}
.margin-top-20{
    margin-top: 20px;
}
.bg-darkddd{
    background-color: #00000040;
}
.bg-ddd{
    background-color: #ddd;
}
.bg-lightddd{
    background-color: #dddddd73;
}
.top-title{
    padding: 20px 0;
    background-color: #00000040;
    text-align: center;
    font-weight: bold;
    /* font-size: 15px; */
}
.float-right{
    float: right;
}
.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}
.balanceInfo:last-child{
    border-right: none !important;
}
.canClick{
    color: #1890ff;
    cursor: pointer;
}
.first-td-text-bold {
    border-right: 1px solid #ddd;
}
.text-bold{
    font-weight: bold;
}
.fill-td-cotent>td:last-child {
    border-right: 1px solid #ddd;
}
.border-left-none {
    border-left: none !important;
}