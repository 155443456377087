.padding-0{
    padding: 0 !important;
}
.margin-0{
    margin: 0 !important;
}
.button-padding {
    padding: 0 41px 0 0 !important;
}
.button-style {
    border: none;
    background: white !important;
}
.downloadCsvStyle{
    margin: 30px 20px 0 0;
}
.viewButtonStyle {
    margin: 30px 0px 0px 0px;
}
.padding-0-5-0-15{
    padding: 0 5px 0 15px;
}
.font-bold {
    font-weight: bold;
}