@import '~antd/dist/antd.css';
#root,.app,.ant-layout{
  margin: 0;
  padding: 0;
  height: 100%;
}

.text-align-left{
  text-align: left;
}

.text-align-right{
  text-align: right;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-menu a{
  color: #fff;
}

.ant-breadcrumb{
  margin-bottom: 20px;
}

.ant-table-wrapper{
  margin-top: 20px;
}

.ant-layout-content{
  overflow: auto;
}

.word-break-all{
  word-break: break-all;
}
.padding-5 {
  padding: 5px;
}
.vertically-end {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-0-10 {
  padding: 0 10px;
}
.display-flex {
  display: flex;
}
.float-right{
  float: right;
}
.color-red {
  color: red;
}